import React, {createContext, useReducer} from 'react';
import Utils from "../../../Utils";
import Constants from "../../../Constants";

const initialState = {};
const authStore = createContext(initialState);
const {Provider} = authStore;
export const actions = {
  SET_USER: 'SET_USER',
  LOGOUT: 'LOGOUT'
}

const UserProvider = ({children}) => {
  const mobile = !window.matchMedia("(min-width: 600px)");
  const tab = !window.matchMedia("(min-width: 780px)");
  const screen =  mobile ? Constants.screen.MOBILE: (tab ? Constants.screen.TAB: Constants.screen.DESKTOP);

  const [user, dispatch] = useReducer((state, action) => {
    if (action.type === actions.SET_USER) {
      return Utils.getUser();
    } else if (action.type === actions.LOGOUT) {
      Utils.logoutUser();
      return {};
    } else {
      throw new Error();
    }
  }, initialState);

  return <Provider value={{user, screen, dispatch}}>{children}</Provider>;
};

export {authStore, UserProvider}
