import {post, get} from "../../common/services/rest/BaseService";
import Utils from "../../Utils";


/**
 * Register channel partner
 *
 * @param id
 * @param data
 * @returns {{}}
 */
export const registerChannelPartner = (data) => {
  return post(`${process.env.REACT_APP_BASE_API_ENDPOINT}/channel_partners`, data).then(json => {
    return json;
  }).catch(error => {
    throw error;
  });
};

/**
 * Fetch organizations
 *
 * @return {Promise<Response | {data: Response, status: *}>}
 */
export const fetchOrganizations = (filters = {}, page = 0, length = 50) => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT}/organizations`, Utils.cleanObject({
    ...filters,
    status: 'active',
    page: page,
    length: length
  })).then(json => {
    return json;
  }).catch(errors => {
    throw(errors);
  });
};
