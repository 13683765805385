import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CheckCircle from '@material-ui/icons/CheckCircle';
import {grey} from "@material-ui/core/colors";

const useStyles = makeStyles({
  root: {
    backgroundColor: grey[800],
    color: grey[50]
  },
  header: {
    display: 'flex',
  },
  details: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 12,
    marginBottom: 0,
    color: grey[50],
  },
  pos: {
    fontSize: 12,
    color: grey[50]
  },
  cover: {
    width: 151,
  },
  table: {
    backgroundColor: '#424242 !important',
    color: grey[50]
  }
});

const StyledTableCell = withStyles((theme) => ({
  root: {
    color: grey[50],
    borderBottomColor: grey[800],
    '&.target': {
      width: 50,
    },
    '& > .MuiSvgIcon-root': {
      fontSize: 14
    }
  },
  body: {
    borderColor: grey[800],
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    color: grey[50],
    borderBottomColor: grey[800]
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return {name, calories, fat, carbs, protein};
}

const rows = [
  createData('ALERID COLD TAB 10S', 159, 160.0),
  createData('ASTHALIN SYP 100 ML', 237, 300.0),
  createData('CIPLOX OZ TABLETS', 262, 250.0),
  createData('CIPLOX-500MG TABLETS 10s', 305, 306.7),
  createData('FEVAGO SUSPENSION 60 ML', 356, 145.0),
];
export const ProductWiseTargetCard = (props) => {
  const classes = useStyles();
  return <Card className={classes.root}>
    <div className={classes.header}>
      <div className={classes.details}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {props.organization}
          </Typography>
          <Typography variant="h4" component="h2">
            {props.value}
          </Typography>
        </CardContent>
      </div>
      <div className={classes.cover}>
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Target
          </Typography>
          <Typography variant="h6" component="h2">
            {props.target}
          </Typography>
        </CardContent>
      </div>
    </div>
    <TableContainer classes={classes.table}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>SKU</StyledTableCell>
            <StyledTableCell align="right">TARGET</StyledTableCell>
            <StyledTableCell align="right">SALE</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right" className="target">
                {row.fat < row.calories ? <CheckCircle color={"primary"}/> : ""} {row.calories}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Card>
};
