import React, {useState} from "react";
import Pagination from "../../../domain/models/Pagination";


export default function TablePagination(props) {
  const [pagination, setPagination] = useState(new Pagination(props.pagesPerSection, props.page, props.totalPages));

  React.useEffect(() => {
    setPagination(new Pagination(props.pagesPerSection, props.page, props.totalPages));
  }, [props.totalPages])

  /**
   * On page click
   *
   * @param page
   * @return {Function}
   */
  let onPageClick = (page) => () => {
    pagination.setPage(page);
    goToPage(page);
  };

  /**
   * Go to page number
   *
   * @param page
   */
  let goToPage = (page) => {
    props.onPageChange(page);
  };

  /**
   * Go to previous section
   */
  let goToPreviousSection = () => {
    pagination.goToPreviousSection();
    goToPage(pagination.page);
  };

  /**
   * Go to previous section
   */
  let goToNextSection = () => {
    pagination.goToNextSection();
    goToPage(pagination.page);
  };

  /**
   * Go to next page
   */
  let goToPreviousPage = () => {
    pagination.goToPreviousPage();
    goToPage(pagination.page);
  };

  /**
   * Go to next page
   */
  let goToNextPage = () => {
    pagination.goToNextPage();
    goToPage(pagination.page);
  };

  return (<nav aria-label="Page navigation example">
    <ul className="pagination m-0">
      {
        !pagination.isFirstSection() &&
        <li className="page-item">
          <a className="page-link" onClick={goToPreviousSection}>
            <i className="fa fa-chevron-circle-left"/>
          </a>
        </li>
      }
      {
        !pagination.isFirstPage() &&
        <li className="page-item">
          <a className="page-link" onClick={goToPreviousPage}>
            <i className="fa fa-chevron-left"/>
          </a>
        </li>
      }
      {
        pagination.sectionPages.map((p, key) => {
          return <li className={"page-item " + (p === props.page ? "active" : "")} key={key}>
            <a className="page-link" onClick={onPageClick(p)}>{p}</a>
          </li>
        })
      }
      {
        !pagination.isLastPage() &&
        <li className="page-item">
          <a className="page-link" onClick={goToNextPage}>
            <i className="fa fa-chevron-right"/>
          </a>
        </li>
      }
      {
        !pagination.isLastSection() &&
        <li className="page-item">
          <a className="page-link" onClick={goToNextSection}>
            <i className="fa fa-chevron-circle-right"/>
          </a>
        </li>
      }
    </ul>
  </nav>);
}