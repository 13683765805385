import React, {useEffect, useState} from "react";
import {getTotals} from "../../../../../domain/services/DashboardService";
import Constants from "../../../../../../Constants";
import {MonthlyConversionGraph} from "./chart";

function useDashboardTotals() {
  const [isLoading, setIsLoading] = useState(true);
  const [totals, setTotals] = useState({});
  /**
   * Get totals
   */
  const requestTotals = () => {
    setIsLoading(true);
    getTotals().then(json => {
      setTotals(json);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
    });
  }
  useEffect(requestTotals, []);

  return [isLoading, totals];
}

export default function DashboardSummary() {
  let [isLoading, totals] = useDashboardTotals();
  if (isLoading)
    return "Preparing...";

  const month = (new Date()).toLocaleString('default', {month: 'long'});
  return <div className="">
    <div className="row no-gutters">
      <div className="col-md-2 col-xs-6 col-sm-6">
        <RupeeCard title="Available Balance" value={totals.available_balance} subTitle="As of date"/>
        <SubCard title="No. of Organizations" value={totals.total_organizations}/>
      </div>
      <div className="col-md-2 col-xs-6 col-sm-6">
        <RupeeCard title="Reward Requests" value={totals.redeemable_points} subTitle="As of date"/>
        <SubCard title="Recharge pts needed" value={totals.redeemable_points - totals.available_balance}/>
      </div>
      <div className="col-md-2 col-xs-6 col-sm-6">
        <RupeeCard title="Redeemable points" value={totals.reward_points} subTitle="As of date"/>
        <SubCard title="Balance needed" value={totals.reward_points - totals.available_balance}/>
      </div>
      <div className="col-md-2 col-xs-6 col-sm-6">
        <RupeeCard title="Converted Points" value={totals.current_month_converted_points} subTitle={month}/>
        <SubCard title="Total Converted" value={totals.converted_points}/>
      </div>
      <div className="col-md-4 col-xs-12 col-sm-12">
        <MonthlyConversionGraph/>
      </div>
    </div>
  </div>;
}

export function RupeeCard({title, value, subTitle}) {
  return <div className="card text-center m-2 mb-3">
    <div className="card-body pb-0">
      <h6 className="font-weight-bold mb-1">{title}</h6>
    </div>
    <div className="card-body px-2">
      <div className="d-flex flex-row ">
        <div className=" pr-2">
          <div className="rupee">
            <i className="fa fa-rupee fa-2x"/>
          </div>
        </div>
        <div className="rupee_content"><h5>{Constants.numberWithCommas(parseInt(value))}</h5></div>
      </div>
    </div>
    <div className="card-body p-0">
      <p>{subTitle}</p>
    </div>
  </div>
}

export function SubCard({title, value}) {
  return <div className="card text-center m-2 sub-card">
    <div className="card-body p-2">
      <h6 className="mb-1 font-weight-bold">{title}</h6>
    </div>
    <div className="card-body pt-0">
      <p className="mb-0">{Constants.numberWithCommas(parseInt(value))}</p>
    </div>
  </div>
}