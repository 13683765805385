export default class RedeemRequest {
  id;
  created_at = "";
  points = 0;
  fullName = "";
  user = {};
  email = "";
  organization = {};
  status = "";
  otp;
  email_verified = "";
  reward = {}
  channel_partner = {}

  /**
   * Constructor
   *
   * @param info
   */
  constructor(info) {
    this.id = info.id;
    this.created_at = info.created_at;
    this.points = info.points;
    this.channel_partner = info.channel_partner;
    this.user = info.user;
    this.reward = info.reward;
    this.email = info.email;
    this.organization = info.organization;
    this.status = info.status;
    this.otp = info.otp;
    this.email_verified = info.email_verified;
  }

  /**
   * Convert to json
   *
   * @return {{}}
   */
  toJson() {
    return {
      id: this.id,
      created_at: this.created_at,
      points: this.points,
      user: this.user,
      email: this.email,
      reward: this.reward,
      organization: this.organization,
      status: this.status
    }
  }
}