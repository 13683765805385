import CPayRegistrationPage from "../pages/CPayRegistrationPage";

let cpayRoutes = [{
  path: "/cpay/registration",
  name: "Cpay Registration form",
  component: CPayRegistrationPage,
  exact: true,
}];

export default cpayRoutes;
