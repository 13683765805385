import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from '@material-ui/icons/ArrowBack';
import User from "../models/User";
import RegistrationForm from "../components/RegistrationForm";
import {getUser} from "../services/FirebaseService";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

const useUser = (mobile) => {
  const [user, setUser] = useState(new User());

  const updateUser = useCallback((field, value) => {
    setUser(User.fromJson({...user.toJson(), [field]: value}));
  }, [user]);

  const fetchUser = () => {
    if (mobile)
      getUser(mobile).then(querySnapshot => {
        if (querySnapshot.docs.length > 0)
          setUser(User.fromSnapshot(querySnapshot.docs[0]));
        else
          setUser(User.fromJson({mobile: mobile}));
      }).catch(error => {
        console.log("Error fetching user: ", error);
      });
    else {
      setUser(User.fromJson({}));
    }
  };
  useEffect(fetchUser, [mobile]);

  return [user, fetchUser, updateUser];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
const CPayRegistrationPage = (props) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [user, fetchUser, updateUser] = useUser(props.match.params.mobile);

  /**
   * On user updated successfully
   */
  const onUserUpdatedSuccessfully = () => {
    Swal.fire({
      title: 'Request submitted!',
      text: 'Your request for user is submitted successfully. Our team will get in touch with you shortly.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'OK!'
    }).then((result) => {
      if (result.value)
        setStep(2);
    });
  };

  const goBack = () => {
    let BackButton = window['BackButton'];
    if (BackButton)
      BackButton.goBack();
  };

  return <Grid container>
    <Grid item xs={12}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton}
                      color="inherit" aria-label="menu" onClick={goBack}>
            <ArrowBack/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            CPay Registration
          </Typography>
        </Toolbar>
      </AppBar>
      {
          step == 2 &&
          <div>Registration compelted.</div>
      }
      {
        step == 1 &&
        <StageTwo user={user}
                  onUserUpdate={onUserUpdatedSuccessfully}
                  onUpdate={updateUser}/>
      }

    </Grid>
  </Grid>;
}
export default CPayRegistrationPage;

const useStageOneStyles = makeStyles((theme) => ({
  actions: {
    "& button": {
      margin: "auto"
    }
  }
}));
const StageTwo = ({onUserUpdate, user, onUpdate}) => {
  return <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom align="center">
        Please fill below details
      </Typography>
      <RegistrationForm user={user}
                     onUserUpdate={onUserUpdate}
                     onUpdate={onUpdate}/>
    </CardContent>
  </Card>
}
