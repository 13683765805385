import GoapptivInsurancePage from "../pages/GoapptivInsurancePage";

let insuranceRoutes = [{
  path: "/insurances/:username",
  name: "Insurance form",
  component: GoapptivInsurancePage,
  exact: true,
}];

export default insuranceRoutes;
