export default class Insurance {
  id;
  username = "";
  first_name = "";
  last_name = "";
  mobile = "";
  gender = "";
  no_of_months = 0;
  date_of_birth = "1976-01-01";
  language = "";
  created_at = "";
  updated_at = "";
  status = "pending";
  unit_price = 150;

  static fromJson(info) {
    let insurance = new Insurance()
    insurance.id = info['id'];
    insurance.username = info['username'];
    insurance.first_name = info['first_name'];
    insurance.last_name = info['last_name'];
    insurance.mobile = info['mobile'];
    insurance.gender = info['gender'];
    insurance.no_of_months = info['no_of_months'] ?? 0;
    insurance.date_of_birth = info['date_of_birth'] ?? "1976-01-01";
    insurance.language = info['language'];
    insurance.status = info['status'] ?? 'pending';
    return insurance;
  }

  static fromSnapshot(data) {
    let insurance = new Insurance();
    insurance.id = data.id;
    insurance.username = data.data()['username'];
    insurance.first_name = data.data()['first_name'];
    insurance.last_name = data.data()['last_name'];
    insurance.mobile = data.data()['mobile'];
    insurance.gender = data.data()['gender'];
    insurance.no_of_months = data.data()['no_of_months'] ?? 0;
    insurance.date_of_birth = data.data()['date_of_birth'] ?? "1976-01-01";
    insurance.language = data.data()['language'];
    insurance.status = data.data()['status'] ?? 'pending';
    return insurance;
  }

  refreshTimeStamp(timestamp) {
    if (!this.created_at)
      this.created_at = timestamp;
    this.updated_at = timestamp;
  }

  toJson() {
    return {
      "id": this.id,
      "username": this.username,
      "first_name": this.first_name,
      "last_name": this.last_name,
      "mobile": this.mobile,
      "gender": this.gender,
      "no_of_months": this.no_of_months,
      "date_of_birth": this.date_of_birth,
      "language": this.language,
      "created_at": this.created_at,
      "updated_at": this.updated_at,
      "status": this.status,
      "total_amount": this.unit_price * this.no_of_months
    }
  }

  totalAmount() {
    return this.unit_price * this.no_of_months;
  }
}
