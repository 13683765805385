import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff"
  },
  header: {
    margin: `0 ${theme.spacing(4)}px auto `,
    paddingTop: 20,
    textAlign: "center",
    '& > *': {
      margin: '0 auto 20px auto',
    },
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  chip: {}
}));
const DocketTrackingCard = (props) => {
  const classes = useStyles();

  return <div className={classes.root}>
    <div className={classes.header}>
      <Avatar src="https://i.ibb.co/fDjv7MB/Untitled.png" className={classes.large}/>
      <Typography gutterBottom variant="h5" component="h2">
        TRACKING STATUS
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Docket Number - {props.data.lr_number}
      </Typography>
      <Chip
        label={`Status: ${props.data.package_status}`}
        className={classes.chip}
      />
    </div>
    <Stepper activeStep={0} orientation="vertical">
      {props.data.tracking_details.map(t => (
        <Step key={t.title}>
          <StepLabel>{t.title}</StepLabel>
          <StepContent>
            <Typography>{t.description}</Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  </div>;
};

export default DocketTrackingCard;
