export default class ServerError {

  /**
   * errors object
   *
   * @type {{}}
   */
  errors = {};

  constructor(message) {
    this.errors = {
      message: message
    };
  }
}