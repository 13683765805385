import {get, post} from "../../../common/services/rest/BaseService";

export const fetchRedeemRequests = (filters) => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests`, filters)
    .then(json => {
      return json;
    }).catch(errors => {
      throw(errors);
    });
}

export const fetchMasters = () => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests/masters`, {length: 50})
    .then(json => {
      return json;
    }).catch(errors => {
      throw(errors);
    });
}

export const releaseCoupons = (filters) => {
  return post(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests/releaseCoupons`, filters)
    .then(json => {
      return json;
    }).catch(errors => {
      throw(errors);
    });
}


/**
 * Send coupon
 *
 * @return {Promise<Response>}
 * @param id
 */
export const sendCoupon = (id) => {
  return post(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests/${id}/sendCoupons`, {})
    .then(json => {
      return json;
    }).catch(errors => {
      throw(errors);
    });
};

/**
 * Resend verification link
 *
 * @return {Promise<Response>}
 * @param id
 */
export const resendVerificationLink = (id) => {
  return post(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests/${id}/sendVerificationEmail`, {})
    .then(json => {
      return json;
    }).catch(errors => {
      throw(errors);
    });
};