import React from "react";
import Chart from "react-google-charts";
import orange from "@material-ui/core/colors/orange";
import {grey} from "@material-ui/core/colors";

export const MonthlyEarningChart = (props) => {
  return <Chart
    height={150}
    legend={{position: "none"}}
    chartType="AreaChart"
    data={[
      ['Months', 'Earning', {role: 'annotation'}],
      ['May, 19', 0.75, 81.75],
      ['Jun, 19', 10, 3.7],
      ['Jul, 19', 12, 26.03],
      ['Aug, 20', 11, 20.9],
      ['Sept, 20', 14.4, 152.6],
      ['Oct, 19', 8.9, 81.75],
      ['Nov, 19', 5.5, 3.7],
      ['Dec, 19', 15.5, 26.03],
      ['Jan, 20', 16.4, 20.9],
      ['Feb, 20', 20, 152.6],
    ]}
    options={{
      backgroundColor: '#fff',
      chartArea: {width: '90%'},
      legend: {position: 'none'},
      colors: [grey[800]],
      hAxis: {
        type: 'line',
        textColor: grey[800],
      },
      vAxis: {
        textPosition: 'none',
        gridlines: {
          color: 'none'
        },
        baselineColor: grey[800]
      },
    }}
  />
};
