import ChannelPartnerRegistration from "../pages/ChannelPartnerRegistration";

let channelPaisaRoutes = [{
  path: "/channelPaisa/register/:token",
  name: "Channel paisa app",
  component: ChannelPartnerRegistration,
  exact: true,
}];

export default channelPaisaRoutes;
