import SplashApp from "../SplashApp";
import ChannelPartnerApp from "../gift/ChannelPartnerApp";
import DashboardApp from "../dashboard/DashboardApp";
import InsuranceApp from "../insurance/InsuranceApp";
import CpayRegistrationApp from "../cpay_registration/CpayRegistrationApp";
import ChannelPaisaApp from "../channel-paisa/ChannelPaisaApp";
import Dashboard from "../wallet/presentation/widgets/features/dashboard/dashboard";
import AuthApp from "../auth/AuthApp";

let indexRoutes = [
  {
    path: "/", name: "Splash page", component: SplashApp, exact: true
  }, 
  {
    path: "/channel_partners", name: "Menu app", component: ChannelPartnerApp, exact: false
  }, 
  {
    path: "/dashboard", name: "Dashboard app", component: DashboardApp, exact: false
  }, 
  {
    path: "/insurances", name: "Insurance app", component: InsuranceApp, exact: false
  }, 
  {
    path: "/cpay/registration", name: "Cpay Registration app", component: CpayRegistrationApp, exact: false
  }, 
  {
    path: "/channelPaisa",
    name: "ChannelPaisa Registration app",
    component: ChannelPaisaApp,
    exact: false
  }, 
  {
    path: "/v2/dashboard", name: "Dashboard", component: Dashboard, exact: true
  }, 
  {
    path: "/v2/auth", name: "Auth", component: AuthApp, exact: true
  }
];

export default indexRoutes;
