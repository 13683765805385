import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import RegistrationForm from "../components/RegistrationForm";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from '@material-ui/icons/ArrowBack';
import {registerChannelPartner, fetchOrganizations} from "../services/channel-paisa-services";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
export default function ChannelPartnerRegistration(props) {
  const classes = useStyles();
  const [registering, setRegistering] = useState(false);
  const [errors, setErrors] = useState({});
  const [position, setPosition] = useState({});
  const [organizations, setOrganizations] = useState([]);

  const fetchLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {

      setPosition({
        lat: position.coords.latitude,
        long: position.coords.longitude
      });
    });
  };
  useEffect(fetchLocation, [registering]);

  const requestOrganizations = () => {
    fetchOrganizations().then(res => {
      setOrganizations(res.organizations);
    }).catch(error => {
      console.log("Error fetching organizations: ", error);
    });
  };
  useEffect(requestOrganizations, [registering]);

  /**
   * On successful registration
   */
  const onSuccessfulRegistration = () => {
    Swal.fire({
      title: 'Registration Successful!',
      text: "Your registration is successfully completed. Please open your what's app for more details.",
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'OK!'
    }).then((result) => {
      if (result.value)
        goBack();
    });
  };

  const onSubmit = (data) => {
    data['address'] = position;
    registerChannelPartner(data).then(doc => {
      onSuccessfulRegistration();
    }).catch(error => {
      setErrors(error.errors);
    });
  };

  const goBack = () => {
    let BackButton = window['BackButton'];
    if (BackButton)
      BackButton.goBack();
  };

  return <Grid container>
    <Grid item xs={12}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            ChannelPaisa Registration
          </Typography>
        </Toolbar>
      </AppBar>
      <StageOne mobile={atob(props.match.params.token)}
                organizations={organizations}
                onSubmit={onSubmit} errors={errors}/>
    </Grid>
  </Grid>;
}

const StageOne = (props) => {
  return <div>
    <div style={{marginTop: 70}}>
      <Typography variant="body2" color="textSecondary" component="p" align="center">
        Please fill below details to register yourself
      </Typography>
    </div>
    <RegistrationForm mobile={props.mobile} errors={props.errors}
                      organizations={props.organizations}
                      onSubmit={props.onSubmit}/>
  </div>;
};
