import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const GiftInfoCard = (props) => {
  const classes = useStyles();

  return <Card className={classes.root}>
    <CardMedia
      className={classes.media}
      image="http://api.channelpaisa.com/images/goapptiv_logo.png"
      title="Paella dish"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        GoApptiv is distributing free gifts
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        GoApptiv is giving this safety kit to all semi , stockists & sales men.
        This contain ,sanitiser spray ,2 reusable masks.
        To avail this gift we will need your delivery address. Please click below to confirm your delivery address.
      </Typography>
    </CardContent>
  </Card>;
};

export default GiftInfoCard;
