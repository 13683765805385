import React, {useEffect, useState} from "react";
import Constants from "../../../../../../Constants";
import {
  fetchMasters,
  fetchRedeemRequests,
  releaseCoupons,
  sendCoupon
} from "../../../../../domain/services/RedeemRequestService";
import RedeemRequest from "../../../../../domain/models/RedeemRequest";
import Status from "../../../independent/status";
import TablePagination from "../../../independent/pagination";
import Filters from "./filters";
import Amount from "../../../independent/amount";
import moment from "moment";
import Utils from "../../../../../../Utils";
import TableLoading from "../../../independent/table_loading";
import Swal from "sweetalert2";
import {resendVerificationLink} from "../../../../../domain/services/RedeemRequestService";

const initialRecords = {
  filters: {
    page: 1, length: 50,
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    verified: false
  },
  redeem_requests: [],
  balance: {},
  summary: {}
};

function useRedeemRequests() {
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(initialRecords);
  const [masters, setMasters] = useState([]);

  /**
   * Get totals
   */
  const requestRedeemRequests = () => {
    setIsLoading(true);
    let filters = Utils.serialize(records.filters);
    if (!filters.verified)
      delete filters.verified;
    fetchRedeemRequests(filters).then(json => {
      setRecords({
        redeem_requests: json['redeem_requests'].map(r => new RedeemRequest(r)),
        total: json['total'],
        filters: records.filters,
        summary: json['summary'],
        balance: json['balance']
      });
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
    });
  }
  useEffect(requestRedeemRequests, [records.filters]);

  const requestMasters = () => {
    fetchMasters().then(json => {
      setMasters(json);
    }).catch(error => {
    });
  }
  useEffect(requestMasters, []);

  let updateFilters = (key, value) => {
    setRecords({...records, filters: {...records.filters, [key]: value}});
  }

  return [isLoading, records, masters, updateFilters];
}

export default function RedeemRequestTable() {
  let [isLoading, records, masters, updateFilters] = useRedeemRequests();
  const [loading, setLoading] = useState(false);

  const releaseAllCoupons = () => {
    Swal.fire({
      title: 'Are you sure!',
      text: "You want to release coupons for the selected filters. And coupons will only be sent to requests which has email and otp verified.",
      icon: 'success',
      onClose: () => {
        releaseCoupons(Utils.serialize(records.filters)).then(json => {
          Swal.fire('Success', "Your request is registered all the coupons are sent for dispatch!");
        }).catch(error => {
          Swal.fire('Warning', "System is already dispatching coupons please try after sometime!");
        });
      }
    });
  }

  const reSendCoupon = (id) => {
    setLoading(true);
    sendCoupon(id).then(res => {
      Swal.fire('Success', "Coupon resent successfully!");
      setLoading(false);
    });
  }

  const sendVerificationLink = (id) => {
    setLoading(true);
    resendVerificationLink(id).then(res => {
      Swal.fire('Success', "Email sent successfully!");
      setLoading(false);
    });
  }

  return <div className="">
    <div className="card mb-0">
      <div className="card-body pb-0">
        <div className="row">
          <div className="col-md-2 col-xs-12 col-sm-12">
            <h5 className="card-title">
              Redeem requests
            </h5>
          </div>
          <div className="col-md-10 col-xs-12 col-sm-12">
            {
              masters.statuses &&
              <Filters filters={records.filters} onChange={updateFilters}
                       masters={masters}/>
            }
          </div>
        </div>
        <hr className="mb-0"/>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered table-dark summary">
          <tbody>
          <tr>
            <td rowSpan="2" className="text-center head">SUMMARY</td>
            <td className="heading">CURRENT BALANCE</td>
            <td className="heading">PENDING REDEEMABLE POINTS</td>
            <td className="heading">CONVERTED REDEEMABLE POINTS</td>
            <td className="heading">ACTIONS</td>
          </tr>
          <tr>
            <td>{Constants.numberWithCommas(records.balance['total_amount'])}</td>
            <td>
              <span className="pr-3">{Constants.numberWithCommas(records.summary['total_pending_amount'])}</span>
              (<Amount balance={records.balance['total_amount'] - records.summary['total_pending_amount']}/>)
            </td>
            <td>
              {Constants.numberWithCommas(records.summary['total_processed_amount'])}
            </td>
            <td>
              {
                (records.balance['total_amount'] - records.summary['total_pending_amount']) > 0 &&
                records.summary['total_pending_amount'] > 0 &&
                <button className="btn btn-primary" onClick={releaseAllCoupons}>
                  RELEASE COUPONS
                </button>
              }
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive card-table-responsive">
        <table className="table table-striped">
          <thead>
          <tr>
            <th>#ID</th>
            <th>DATE</th>
            <th>PARTY</th>
            <th>ORGANIZATION</th>
            <th>VENDOR</th>
            <th>AMOUNT</th>
            <th>EMAIL</th>
            <th>OTP VERIFIED</th>
            <th>STATUS</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            isLoading &&
            <TableLoading colspan="9"/>
          }
          {
            records.redeem_requests.map(b => {
              return <tr key={b.id}>
                <td>{b.id}</td>
                <td>{Constants.standardDateFormat(b.created_at)}</td>
                <td>{b.channel_partner.firm_name}</td>
                <td>{b.organization.name}</td>
                <td>{b.reward.redeem_partner.name}</td>
                <td>{b.points}</td>
                <td><span className="pr-2">
                  <Status status={b.email_verified}/></span>{b.email}
                  {
                    !b.email_verified &&
                    <button className="btn btn-outline-primary btn-xs rounded ml-2" disabled={loading}
                            onClick={() => sendVerificationLink(b.id)}>Resend link</button>
                  }
                </td>
                <td className="text-center"><Status status={!b.otp && b.otp != ""}/></td>
                <td>
                  {b.status}
                  {
                    b.status === Constants.IN_ACTIVE &&
                    <button className="btn btn-primary btn-xs rounded ml-2" disabled={loading}
                            onClick={() => reSendCoupon(b.id)}>Resend Coupon</button>
                  }
                </td>
                <td></td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        <TablePagination pagesPerSection={10}
                         page={records.filters.page}
                         totalPages={Constants.calculatePages(records.total, 50)}
                         onPageChange={(page) => updateFilters('page', page)}/>
      </div>
    </div>
  </div>;
}