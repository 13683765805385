export default class BadRequestError {

  /**
   * errors object
   *
   * @type {{}}
   */
  errors = {};

  constructor(errors) {
    this.errors = errors;
  }
}