import ChannelPartnerDashboard from "../pages/ChannelPartnerDashboard";

let dashboardRoutes = [{
  path: "/dashboard/forChannelPartner/:id",
  name: "Dashboard for channel partner",
  component: ChannelPartnerDashboard,
  exact: true,
}];

export default dashboardRoutes;
