import React, {useContext, useEffect} from "react";
import NavigationComponent from "../nav/navigation";
import {actions, authStore} from "../../../../domain/controllers/userController";
import DashboardSummary from "./view/summary";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './dashboard.css';
import './wallet.css';
import "react-datepicker/dist/react-datepicker.css";
import BalanceList from "./view/balance";
import RedeemRequestTable from "./view/redeem_requests";

export default function Dashboard() {
  const {dispatch, user} = useContext(authStore);

  useEffect(() => {
    dispatch({type: actions.SET_USER});
  }, [user.id]);

  if (!user.id)
    return <div className="loading-content">
      <h5>
        Preparing...
      </h5>
    </div>;

  return <div className="fluid-container">
    <NavigationComponent/>
    <div className="dashboard_root mx-2">
      <div>
        <DashboardSummary/>
      </div>
      <hr/>
      <BalanceList />
      <hr/>
      <RedeemRequestTable />
    </div>
  </div>;
}