import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";
import {grey} from "@material-ui/core/colors";

const useStyles = makeStyles({
  root: {
    maxWidth: 120,
    margin: 5,
    color: grey[800]
  },
  title: {
    fontSize: 12,
    marginBottom: 16,
    color: grey[800]
  },
  pos: {
    fontSize: 12,
    color: grey[800]
  },
});
export const DataCard = (props) => {
  const classes = useStyles();
  return <Card className={classes.root}>
    <CardContent>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {props.title}
      </Typography>
      <Typography variant="h6" component="h2">
        {props.value}
      </Typography>
      <Typography className={classes.pos} color="textSecondary">
        {props.type}
      </Typography>
    </CardContent>
  </Card>
};
