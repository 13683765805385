import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Utils from "../Utils";
import { get, post } from "../common/services/rest/BaseService";
import Cookies from "js-cookie";

export default function AuthApp(props) {
  let location = useLocation();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  let validatePassword = () => {
    post(
      `${process.env.REACT_APP_BASE_API_ENDPOINT}/auth/validate_wallet_password`,
      {
        password: btoa(password),
      }
    )
      .then((json) => {
        saveToken();
      })
      .catch((errors) => {
        setMessage("Invalid password");
      });
  };

  /**
   * Login success callback
   */
  let saveToken = () => {
    // const searchParams = new URLSearchParams(location.search);
    // let token = searchParams.get('token');
    let token = Cookies.get("token");
    Utils.setAuthToken(token);
    fetchUser(token);
  };

  /**
   * Fetch user
   *
   * @param {} token
   */
  let fetchUser = (token) => {
    get(`${process.env.REACT_APP_BASE_API_ENDPOINT}/auth/forToken/${token}`, {})
      .then((json) => {
        Utils.setUser(json.user);
        props.history.push("/v2/dashboard");
      })
      .catch((errors) => {
        throw errors;
      });
  };

  return (
    <div className="fluid-container">
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="p-5">
            <div className="form-group">
              <label>Enter your wallet password</label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
            <button className="btn btn-primary" onClick={validatePassword}>
              SUBMIT
            </button>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  );
}
