import {get} from "../../../common/services/rest/BaseService";


export const getBalanceTotals = () => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/organizations/totals`, {}).then(json => {
    return json;
  }).catch(errors => {
    throw(errors);
  });
}

export const getAccountTotals = () => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/points/totals`, {}).then(json => {
    return json;
  }).catch(errors => {
    throw(errors);
  });
}

export const getRedeemRequestTotals = () => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests/totals`, {}).then(json => {
    return json;
  }).catch(errors => {
    throw(errors);
  });
}
export const fetchMonthlyTotals = () => {
  return get(`${process.env.REACT_APP_BASE_API_ENDPOINT_V3}/reward_requests/monthlyTotals`, {}).then(json => {
    return json;
  }).catch(errors => {
    throw(errors);
  });
}

/**
 * Fetch totals
 *
 * @return {function(*): Promise<Response>}
 */
export const getTotals = () => {
  return Promise.all([getBalanceTotals(), getAccountTotals(), getRedeemRequestTotals()])
    .then(([balances, accounts, redeemRequests]) => {
      return {
        'available_balance': balances.total_amount,
        'total_organizations': balances.total_accounts,
        'reward_points': accounts.total_amount,
        'redeemable_points': redeemRequests.total_pending_amount,
        'converted_points': balances.total_consumed,
        'current_month_converted_points': redeemRequests.current_month
      }
    });
};