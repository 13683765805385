import React from "react";
import Constants from "../../../../Constants";

export default function Amount({balance}) {
  return <span>
    <span className="pr-2">{Constants.numberWithCommas(balance.toFixed(0))}</span>
    {
      balance < 0 && <i className="fa fa-arrow-down text-danger" />
    }
    {
      balance >= 0 && <i className="fa fa-arrow-up text-success" />
    }
  </span>
}