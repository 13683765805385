import React from "react";

class SplashApp extends React.Component {

  /**
   * Component did mount
   */
  componentWillMount() {
    this.props.history.push('/channel_partners');
  }

  render() {
    return (<div className="text-center container-fluid">
      LOADING ...
    </div>);
  }
}

export default SplashApp;
