import React from "react";
import Chart from "react-google-charts";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";

export const OrganizationSaleTargetChart = (props) => {
  return <Chart
    height={150}
    legend={{position: "none"}}
    chartType="BarChart"
    data={[
      ['Organizations', 'Sales', 'Target', { role: 'annotation' }],
      ['Cipla Optimus', 20, 80, "20 %"],
      ['Zydus', 60, 40, "40 %"],
      ['Pfizer icon', 80, 20, "20 %"],
      ['Pfizer aspira', 90, 10, "10 %"],
      ['Pfizer Respi', 90, 10, "90 %"],
      ['Pfizer Pain & Gi', 65, 35, "35 %"],
    ]}
    options={{
      isStacked: true,
      backgroundColor: '#fff',
      bar: { groupWidth: "70%" },
      chartArea: {width: '70%', left: '30%', top: 0, bottom: 0, right: '10%'},
      legend: { position: 'none' },
      colors: [orange[700], grey[400]],
      hAxis: {
        textColor: grey[800],
        baselineColor: '#fff',
        gridlines: {
          color: 'none'
        },
      },
      vAxis: {
        textColor: grey[800],
        baselineColor: '#fff'
      },
    }}
  />
};
