import React, {useCallback} from "react";
import DatePicker from "react-datepicker";
import _ from 'lodash';

export default function Filters({filters, onChange, masters}) {

  const delayedQuery = _.debounce(q => {
    onChange(q.key, q.value);
  }, 700);

  return <>
    <div className="row no-gutters">
      <div className="col-md-2 col-xs-12 col-sm-6">
        <div className="form-group">
          <small>Search</small>
          <input type="text" className="form-control" placeholder={"Search"}
                 onChange={(event) => delayedQuery({key: 'q', value: event.target.value})}/>
        </div>
      </div>
      <div className="col-md-1 col-xs-12 col-sm-6">
        <div className="form-group">
          <small>Status</small>
          <select value={filters.status} className="form-control"
                  onChange={(event) => onChange('status', event.target.value)}>
            <option value="">Select status</option>
            {
              masters.statuses.map(r => <option key={r} value={r}>{r}</option>)
            }
          </select>
        </div>
      </div>
      <div className="col-md-2 col-xs-12 col-sm-6">
        <div className="form-group">
          <small>Organization</small>
          <select value={filters.org_id} className="form-control"
                  onChange={(event) => onChange('org_id', event.target.value)}>
            <option value="">Select Org</option>
            {
              masters.organizations.map(o => <option key={o.id} value={o.id}>{o.name}</option>)
            }
          </select>
        </div>
      </div>
      <div className="col-md-2 col-xs-12 col-sm-6">
        <div className="form-group">
          <small>Vendor</small>
          <select value={filters.vendor_id} className="form-control"
                  onChange={(event) => onChange('vendor_id', event.target.value)}>
            <option value="">Select Vendor</option>
            {
              masters.vendors.map(v => <option key={v.id} value={v.id}>{v.name}</option>)
            }
          </select>
        </div>
      </div>
      <div className="col-md-2 col-xs-12 col-sm-6">
        <div className="form-group">
          <small>State date</small>
          <DatePicker className="form-control"
                      dateFormat="dd, MMM, yyyy"
                      selected={filters.startDate}
                      onChange={date => onChange('startDate', date)}
                      selectsStart
                      startDate={filters.startDate}
                      endDate={filters.endDate}/>
        </div>
      </div>
      <div className="col-md-2 col-xs-12 col-sm-6">
        <div className="form-group">
          <small>End date</small>
          <DatePicker className="form-control"
                      dateFormat="dd, MMM, yyyy"
                      selected={filters.endDate}
                      onChange={date => onChange('endDate', date)}
                      selectsEnd
                      startDate={filters.startDate}
                      endDate={filters.endDate}
                      minDate={filters.startDate}/>
        </div>
      </div>
      <div className="col-md-1 col-xs-12 col-sm-6">
        <div className="form-group mt-4">
          <button className={"btn btn-xs " + (filters.verified ? "btn-primary" : "btn-default")}
                  onClick={() => onChange("verified", !filters.verified)}>
            SHOW ONLY VERIFIED
          </button>
        </div>
      </div>
    </div>
  </>;
}