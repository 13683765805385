import React from "react";
import Chart from "react-google-charts";
import orange from "@material-ui/core/colors/orange";
import {grey} from "@material-ui/core/colors";

export const MonthlySaleChart = (props) => {
  return <Chart
    height={150}
    legend={{position: "none"}}
    chartType="ColumnChart"
    data={[
      ['Months', 'Sales', { role: 'annotation', color: grey[800], }],
      ['May, 19', 81.75, 81.75],
      ['Jun, 19', 3.7, 3.7],
      ['Jul, 19', 26.03, 26.03],
      ['Aug, 20', 20.9, 20.9],
      ['Sept, 20', 152.6, 152.6],
      ['Oct, 19', 81.75, 81.75],
      ['Nov, 19', 3.7, 3.7],
      ['Dec, 19', 26.03, 26.03],
      ['Jan, 20', 20.9, 20.9],
      ['Feb, 20', 152.6, 152.6],
    ]}
    options={{
      backgroundColor: grey[50],
      bar: { groupWidth: "70%" },
      chartArea: {width: '100%'},
      legend: { position: 'none' },
      colors: [orange[600]],
      hAxis: {
        type: 'line',
        textColor: grey[800]
      },
      vAxis: {
        textColor: grey[800],
        textPosition: 'none',
        gridlines: {
          color: 'none'
        },
        baselineColor: grey[800]
      },
      annotations: {
        textStyle: {
          color: grey[800]
        }}
    }}
  />
};
