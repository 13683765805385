export default class Address {
  id = "";
  channel_partner_id = "";
  address_id = "";
  line = "";
  landmark = "";
  area = "";
  taluka = "";
  district = "";
  state = "";
  country = "";
  pincode = "";
  created_at = "";
  updated_at = "";

  constructor(info) {
    this.id = info['id'];
    this.address_id = info['address_id'];
    this.channel_partner_id = info['channel_partner_id'];
    this.line = info['line'];
    this.landmark = info['landmark'];
    this.area = info['area'];
    this.taluka = info['taluka'];
    this.district = info['district'];
    this.state = info['state'];
    this.country = info['country'];
    this.pincode = info['pincode'];
    this.created_at = info['created_at'];
    this.updated_at = info['updated_at'];
  }

  static fromSnapshot(data) {
    let address = new Address({});
    address.id = data.id;
    address.address_id = data.data()['address_id'];
    address.channel_partner_id = data.data()['channel_partner_id'];
    address.line = data.data()['line'];
    address.landmark = data.data()['landmark'];
    address.area = data.data()['area'];
    address.taluka = data.data()['taluka'];
    address.district = data.data()['district'];
    address.state = data.data()['state'];
    address.country = data.data()['country'];
    address.pincode = data.data()['pincode'];
    address.created_at = data.data()['created_at'];
    address.updated_at = data.data()['updated_at'];
    return address;
  }

  refreshTimeStamp(timestamp) {
    if (!this.created_at)
      this.created_at = timestamp;
    this.updated_at = timestamp;
  }

  toJson() {
    return {
      "address_id": this.address_id,
      "channel_partner_id": this.channel_partner_id,
      "line": this.line,
      "landmark": this.landmark,
      "area": this.area,
      "taluka": this.taluka,
      "district": this.district,
      "state": this.state,
      "country": this.country,
      "pincode": this.pincode,
      "created_at": this.created_at,
      "updated_at": this.updated_at
    }
  }
}
