import moment from "moment";

export default class Utils {

  /**
   * Get auth token
   *
   * @return {*}
   */
  static getAuthToken = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      return Utils.getAuthTokenFromLocalStorage();
    return "";
  };

  /**
   * Set auth token
   * @param authToken
   */
  static setAuthToken = (authToken) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      this.setAuthTokenToLocalStorage(authToken);
  };

  /**
   * Get user
   *
   * @return {*}
   */
  static getUser = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      return Utils.getUserFromLocalStorage();
    return {};
  };

  /**
   * Set user
   *
   * @param user
   */
  static setUser = (user) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      this.setUserToLocalStorage(user);
  };

  /**
   * Logout user
   */
  static logoutUser = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      this.logoutFromLocalStorage();
  };

  /**
   * Get mobile
   *
   * @return {*}
   */
  static getMobile = () => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      return Utils.getMobileFromLocalStorage();
    return {};
  };

  /**
   * Set mobile
   * @param mobile
   */
  static setMobile = (mobile) => {
    if (process.env.REACT_APP_AUTH_TOKEN_STORAGE === 'local_storage')
      this.setMobileTokenToLocalStorage(mobile);
  };

  /**
   * Get auth token from local storage DB
   *
   * @return {*}
   */
  static getAuthTokenFromLocalStorage = () => {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_KEY);
    if (!token)
      return "";
    return token;
  };

  /**
   * Set auth token to local storage
   *
   * @param authToken
   */
  static setAuthTokenToLocalStorage = (authToken) => {
    localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_KEY, authToken);
  };

  /**
   * Get user from local storage DB
   *
   * @return {*}
   */
  static getUserFromLocalStorage = () => {
    const user = localStorage.getItem(process.env.REACT_APP_AUTH_USER);
    if (!user)
      return {};
    return JSON.parse(user);
  };

  /**
   * Set user to local storage
   *
   * @param user
   */
  static setUserToLocalStorage = (user) => {
    localStorage.setItem(process.env.REACT_APP_AUTH_USER, JSON.stringify(user));
  };

  /**
   * Get mobile from local storage DB
   *
   * @return {*}
   */
  static getMobileFromLocalStorage = () => {
    const mobile = localStorage.getItem(process.env.REACT_APP_USER_MOBILE);
    if (!mobile)
      return {};
    return mobile;
  };

  /**
   * Set mobile to local storage
   *
   * @param mobile
   */
  static setMobileTokenToLocalStorage = (mobile) => {
    localStorage.setItem(process.env.REACT_APP_USER_MOBILE, mobile);
  };

  /**
   * Logout from local storage
   */
  static logoutFromLocalStorage = () => {
    localStorage.removeItem(process.env.REACT_APP_AUTH_USER);
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_KEY);
  }

  /**
   * Clean object
   * 
   * @param {*} obj 
   */
  static cleanObject = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        if (obj[key] === null || obj[key] === undefined || obj[key] === "undefined") {
          delete obj[key];
        }
    }
    return obj
  }

  /**
   * Serialize object
   * @param data
   * @returns {*}
   */
  static serialize(data) {
    let serializedData = {};
    for (const key in data) {
      if (data[key] instanceof Date)
        serializedData[Utils.camelToUnderScore(key)] = moment(data[key]).format("YYYY-MM-DD");
      else if (typeof data[key] === "boolean")
        serializedData[Utils.camelToUnderScore(key)] = data[key]? 1: 0;
      else
        serializedData[Utils.camelToUnderScore(key)] = data[key];
    }
    return serializedData;
  }

  static camelToUnderScore(value) {
    return value.split(/(?=[A-Z])/).join('_').toLowerCase();
  }
}
