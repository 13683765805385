import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Insurance from "../models/Insurance";
import InsuranceForm from "../components/InsuranceForm";
import {getInsurance} from "../services/FirebaseService";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

const useInsurance = (username) => {
  const [insurance, setInsurance] = useState(null);

  const updateInsurance = useCallback((field, value) => {
    setInsurance(Insurance.fromJson({...insurance.toJson(), [field]: value}));
  }, [insurance]);

  const fetchInsurance = () => {
    getInsurance(username).then(querySnapshot => {
      if (querySnapshot.docs.length > 0)
        setInsurance(Insurance.fromSnapshot(querySnapshot.docs[0]));
      else
        setInsurance(Insurance.fromJson({username: username}));
    }).catch(error => {
      console.log("Error fetching insurance: ", error);
    });
  };
  useEffect(fetchInsurance, [username]);

  return [insurance, fetchInsurance, updateInsurance];
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
const GoapptivInsurancePage = (props) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [insurance, fetchInsurance, updateInsurance] = useInsurance(props.match.params.username);

  /**
   * On insurance updated successfully
   */
  const onInsuranceUpdatedSuccessfully = () => {
    Swal.fire({
      title: 'Request submitted!',
      text: 'Your request for insurance is submitted successfully. Our team will get in touch with you shortly.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'OK!'
    }).then((result) => {
      if (result.value)
        fetchInsurance();
    });
  };

  const goBack = () => {
    let BackButton = window['BackButton'];
    if (BackButton)
      BackButton.goBack();
  };

  return <Grid container>
    <Grid item xs={12}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton}
                      color="inherit" aria-label="menu" onClick={goBack}>
            <ArrowBack/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            GoApptiv insurance program
          </Typography>
        </Toolbar>
      </AppBar>
      {
        insurance && (insurance.status === 'processing' ||insurance.status === 'paid')
        && <StageThree insurance={insurance}/>
      }
      {
        insurance && insurance.status === 'pending' && <React.Fragment>
          {
            step === 1 &&
            <StageOne goToInsuranceForm={() => setStep(2)} insurance={insurance}/>
          }
          {
            step === 2 &&
            <StageTwo insurance={insurance}
                      onInsuranceUpdate={onInsuranceUpdatedSuccessfully}
                      onUpdate={updateInsurance}/>
          }
        </React.Fragment>
      }
    </Grid>
  </Grid>;
}
export default GoapptivInsurancePage;

const useStageOneStyles = makeStyles((theme) => ({
  actions: {
    "& button": {
      margin: "auto"
    }
  }
}));
const StageOne = (props) => {
  const classes = useStageOneStyles();
  const {goToInsuranceForm, insurance} = props;

  const checkEligibility = () => {
    if (insurance.id)
      goToInsuranceForm();
    else
      Swal.fire({
        title: 'You are not eligible!',
        text: 'You should have minimum 150 points in your Cipla Optimus wallet.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK!'
      }).then((result) => {
      });
  }

  return <div>
    <div style={{margin: 10, marginBottom: 40}}>
      <Card>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h6">
              Insurance includes
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              <ul>
                <li>Unlimited teleconsultations with Clinikk’s doctors (available 24*7)</li>
                <li>24*7 Medical Support by Clinikk Health Assistants ( in 11 vernacular languages)</li>
                <li>20% discount on medicines & lab tests (home delivered)</li>
                <li>Health and wellness engagement through calls, SMS & WhatsApp</li>
              </ul>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h6">
              Health Insurance provides
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              <ul>
                <li>Sum Insured : INR 1 Lakh or 2 Lakh</li>
                <li>Covers :1 Adult (Self Only)</li>
                <li>Age Limit : 18 to 60 years</li>
                <li>Accidental Hospitalization : Covered from Day 1</li>
              </ul>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.actions}>
          <Button size="small" variant="contained" color="primary" onClick={checkEligibility}>
            Click here to avail insurance
          </Button>
        </CardActions>
      </Card>
    </div>
  </div>
};

const StageTwo = ({onInsuranceUpdate, insurance, onUpdate}) => {
  return <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom align="center">
        Please fill below details
      </Typography>
      <InsuranceForm insurance={insurance}
                     no_of_months={insurance.no_of_months}
                     onInsuranceUpdate={onInsuranceUpdate}
                     onUpdate={onUpdate}/>
    </CardContent>
  </Card>
}

const useStageThreeStyles = makeStyles((theme) => ({
  root: {}
}));
const StageThree = (props) => {
  const classes = useStageThreeStyles();
  const {insurance} = props;
  return <Card className={classes.root}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Your insurance request is under process.
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Your insurance card will be sent to your email id.
      </Typography>
    </CardContent>
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2}>Your details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row"><b>FirstName</b></TableCell>
            <TableCell align="right">{insurance.first_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"><b>LastName</b></TableCell>
            <TableCell align="right">{insurance.last_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"><b>Date of Birth</b></TableCell>
            <TableCell align="right">{insurance.date_of_birth}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"><b>Mobile</b></TableCell>
            <TableCell align="right">{insurance.mobile}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row"><b>Language</b></TableCell>
            <TableCell align="right">{insurance.language}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Card>
}
