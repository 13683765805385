import moment from "moment";
import * as lodash from "lodash";

export default class Constants {
  // redeem_request thumb and redeem_request image
  static INVOICE_THUMB_IMAGE = "/images/invoice_thumb.png";
  static INVOICE_IMAGE = "/images/redeem_request.png";
  static LOGO_IMAGE = "/images/logo.jpg";

  // role constants
  static ADMIN = "ADMIN";
  static WALLET_USER = "WALLET_USER";
  static ORG_ADMIN = "ORG_ADMIN";

  // prepaid card status constant

  static PREPAID_CARD_STATUS = {
    ready: "ready",
    delivered: "delivered",
    printed: "printed",
    invalid: "invalid",
    dispatched: "dispatched",
  };

  // coupon status
  static COUPON_SENT = 'coupon_sent';
  static IN_ACTIVE = 'inactive';

  // default page length
  static PAGINATION_LENGTH = 10;

  static REDEEM_REQUEST_STATUSES = [
    'coupon_sent',
    'under_process'
  ]
  static GOAPPTIV_ADDRESS =
    "GoApptiv Pvt Ltd,SB 16, High street corporate park, Kapurbawdi, Thane West, Maharashtra 400607";

  /**
   * Get range of number
   *
   * @param start
   * @param end
   * @return {Array}
   */
  static getRange(start, end) {
    let range = [];
    for (let i = start; i <= end; i++) range.push(i);
    return range;
  }

  /**
   * Calculate pages from total records
   *
   * @param totalRecords
   * @return {number}
   */
  static calculatePages(totalRecords, length = 0) {
    return Math.ceil(totalRecords / (length == 0 ? Constants.PAGINATION_LENGTH: length));
  }

  /**
   * Format date
   *
   * @param date
   * @return {string}
   */
  static standardDateFormat(date) {
    return moment(date, "YYYY-MM-DD").format("DD MMM 'YY");
  }

  /**
   * Remove empty fields
   * @param dict
   */
  static removeEmptyFields(dict) {
    return lodash.pickBy(dict, lodash.identity);
  }

  static screen =  {
    MOBILE: 'mobile',
    TAB: 'tab',
    DESKTOP: 'desktop'
  }

  static numberWithCommas(x) {
    if (!x)
      return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
