export default class Balance {
  id = 0;
  name = "";
  organization = {};
  current_balance = 0;
  redeemable_points = 0;
  total_pending_amount = 0;
  total_processed_amount = 0;
  min_pending_created_at = "";

  constructor(info) {
    this.id = info.id;
    this.name = info.name;
    this.current_balance = info.current_balance;
    this.redeemable_points = info.redeemable_points;
    this.total_pending_amount = info.total_pending_amount;
    this.total_processed_amount = info.total_processed_amount;
    this.min_pending_created_at = info.min_pending_created_at;
  }

  pendingRecharge() {
    return this.current_balance - this.total_pending_amount;
  }

  redeemableRecharge() {
    return this.current_balance - this.redeemable_points
  }

  /**
   * To Json
   *
   * @return {{}}
   */
  toJson() {
    return {}
  }
}
