import GoApptivGiftStatus from "../pages/GoApptivGiftStatus";

let channelPartnerRoutes = [{
  path: "/channel_partners/:id/giftStatus",
  name: "Channel partner address update",
  component: GoApptivGiftStatus,
  exact: true,
}];

export default channelPartnerRoutes;
