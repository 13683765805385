import React, {Fragment, useContext} from "react";
import {NavLink} from "react-router-dom";
import Constants from "../../../../../Constants";
import {authStore} from "../../../../domain/controllers/userController";

export default function NavigationComponent(props) {
  const {dispatch, user} = useContext(authStore);
  const [show, setShow] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);

  if (!user || !user.id)
    return "";

  return <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
    <a className="navbar-brand" href="#">Wallet App</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation" onClick={() => setShow(!show)}>
      <span className="navbar-toggler-icon"/>
    </button>
    <div className={"collapse navbar-collapse " + (show ? "show" : "")} id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        {
          (user.role_str === Constants.ADMIN) &&
          <Fragment>
            <li className="nav-item">
              <NavLink to="/v2/dashboard" className="nav-link">Dashboard</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/prepaid-cards" className="nav-link">
                Prepaid Cards
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/field-forces" className="nav-link">
                Field Forces
              </NavLink>
            </li>
          </Fragment>
        }
      </ul>
      {
        user && user.id && <ul className="nav nav-pills text-white">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
               onClick={() => setShowProfile(!showProfile)}>
              <i className="fa fa-user-circle-o mr-1"/>{user.full_name}
            </a>
            <div className={"dropdown-menu " + (showProfile ? "show" : "")}
                 aria-labelledby="navbarDropdownMenuLink">
              <NavLink to="/auth/logout" className="dropdown-item">Logout</NavLink>
              <NavLink to="/auth/changePassword" className="dropdown-item">Change Password</NavLink>
            </div>
          </li>
        </ul>
      }
    </div>
  </nav>;
}
