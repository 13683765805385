import {db} from "../../firebase";

export function getGiftStatusForChannelPartner(channelPartnerId) {
  return db.collection("goapptiv_free_gifts")
    .where("channel_partner_id", '==', channelPartnerId)
    .limit(1)
    .get();
}


export function getDeliveryAddressById(id) {
  return db.collection("delivery_addresses")
    .doc(id)
    .get();
}

export function markAddressVerifiedForGift(id) {
  return db.collection("goapptiv_free_gifts")
    .doc(id)
    .update({status: 'address_verified', package_status: 'packing'});
}
