import React from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import {useForm} from "react-hook-form";
import {db} from "../../firebase";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10
  },
}));
const RegistrationForm = (props) => {
  const {register, handleSubmit} = useForm();
  const {errors, organizations} = props;
  const classes = useStyles();

  /**
   * Save address
   *
   * @param data
   */
  const onSubmit = data => {
    props.onSubmit(data);
  };

  const handleMultiChange = event => {
    console.log(event.target.value);
  }

  return <form noValidate className={classes.root} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
    <TextField fullWidth={true} variant="filled" margin="normal" name="mobile"
               label="Mobile" InputLabelProps={{shrink: true}}
               inputRef={register({})}
               disabled={true}
               defaultValue={props.mobile}
               error={!!errors.mobile}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="firm_name"
               label="Name of Retailer/Shop" InputLabelProps={{shrink: true}}
               inputRef={register({})}
               error={!!errors.firm_name}/>
     <FormControl fullWidth variant="filled" margin="normal">
       <InputLabel>Type</InputLabel>
       <Select native
               inputRef={register({})}
               onChange={handleMultiChange}
               inputProps={{name: 'channel_partner_type_id'}} error={!!errors.channel_partner_type_id}>
         <option aria-label="None" value=""/>
         <option value={1}>Stockist</option>
         <option value={2}>Semi</option>
         <option value={3}>Hub Chemist</option>
         <option value={4}>Retailer</option>
         <option value={5}>HealthCare Provider</option>
       </Select>
     </FormControl>
    <TextField fullWidth={true} variant="filled" margin="normal" name="gst_no"
               label="GST no." InputLabelProps={{shrink: true}}
               inputRef={register({})} error={!!errors.gst_no}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="dl_no"
               label="Drug License No." InputLabelProps={{shrink: true}}
               inputRef={register({})} error={!!errors.dl_no}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="address"
               label="Address" InputLabelProps={{shrink: true}}
               inputRef={register({})} error={!!errors.address}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="annual_turnover"
               label="Annual Turnover [In Lakh]" InputLabelProps={{shrink: true}}
               inputRef={register({})} error={!!errors.annual_turnover}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="no_of_customer"
               label="No. of customer per month" InputLabelProps={{shrink: true}}
               inputRef={register({})} error={!!errors.no_of_customer}/>
    <FormControl fullWidth variant="filled" margin="normal">
       <InputLabel>Select Organization</InputLabel>
       <Select native
               inputRef={register({})}
               onChange={handleMultiChange}
               inputProps={{name: 'creator_organization_id'}} error={!!errors.creator_organization_id}>
         <option aria-label="None" value=""/>
         {
           organizations.map(org => {
             return <option value={org.id} key={org.id}>{org.name}</option>;
           })
         }
       </Select>
    </FormControl>
    <Button variant="contained" color="primary" size="medium" startIcon={<SaveIcon/>} fullWidth type="submit">
      Save
    </Button>
  </form>;
};

export default RegistrationForm;
