import React from "react";
import dashboardRoutes from "./routes/index.js";
import {Route, Switch} from "react-router-dom";
import './css/index.css';

class DashboardApp extends React.Component {
  render() {
    return (<Switch>
      {
        dashboardRoutes.map((prop, key) => {
          return <Route path={prop.path} component={prop.component} key={key}
                        exact={prop.exact}/>;
        })
      }
    </Switch>);
  }
}

export default DashboardApp;
