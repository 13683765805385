import * as React from "react";
import {Chart} from "react-google-charts";
import {useEffect, useState} from "react";
import {fetchMonthlyTotals, getTotals} from "../../../../../domain/services/DashboardService";


function useMonthlyConversion() {
  const [isLoading, setIsLoading] = useState(false);
  const [totals, setTotals] = useState([]);
  /**
   * Get totals
   */
  const requestTotals = () => {
    setIsLoading(true);
    fetchMonthlyTotals().then(json => {
      setTotals(json);
      setIsLoading(false);
    })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }
  useEffect(requestTotals, []);

  return [isLoading, totals];
}

export function MonthlyConversionGraph() {
  const [isLoading, totals] = useMonthlyConversion()

  let monthlyConverted = []
  if (totals) {
    monthlyConverted = totals.map((item) => {
      return [item.month_str, parseInt((item.total_processed_amount/1000).toFixed(1))];
    });
  }

  return <div className="card m-2 text-center">
    <div className="card-body pb-0">
      <h6 className="font-weight-bold mb-1">Amount Converted(in thousands)</h6>
    </div>
    <div className="card-body">
      <Chart chartType="ColumnChart"
             data={[["Months", "Converted"], ...monthlyConverted]}
             options={{
               chartArea: {left: 20, top: 20, right: 10, bottom: 30},
               colors: ['#000000'],
             }}
             width="100%" height="180px"
      />
    </div>
  </div>
}