import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {MonthlySaleChart} from "../components/MonthlySaleChart";
import Typography from "@material-ui/core/Typography";
import {DataCard} from "../components/DataCard";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {InputBase, withStyles} from "@material-ui/core";
import {MonthlyEarningChart} from "../components/MonthlyEarningChart";
import {ProductWiseTargetCard} from "../components/ProductWiseTargetCard";
import {OrganizationSaleTargetChart} from "../components/OrganizationSaleTargetChart";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import orange from "@material-ui/core/colors/orange";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: orange[600],
    height: '100vh'
  },
  header: {
    textAlign: 'center',
    color: '#fff',
  },
  darkSubHeader: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 12
  },
  lightSubHeader: {
    textAlign: 'center',
    color: orange[600],
    backgroundColor: '#fff',
    fontSize: 12
  },
  headerPadding: {
    paddingTop: 20
  },
  tabs: {
    backgroundColor: '#fff'
  }
}));
export default function ChannelPartnerDashboard(props) {
  const classes = useStyles();
  return <Grid container className={classes.root}>
    <Grid item xs={12}>
      <Typography variant="h6" center className={classes.header}>
        DASHBOARD
      </Typography>
      <OrganizationSelect/>
      <Typography variant="h6" center className={classes.lightSubHeader}>
        Monthly Sales
      </Typography>
      <MonthlySaleChart/>
    </Grid>
    <Grid item xs={12}>
      <DataCards/>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6" center className={classes.lightSubHeader}>
        Monthly earnings
      </Typography>
      <MonthlyEarningChart/>
    </Grid>
    <Grid item xs={12}>
    <Typography variant="h6" center className={classes.lightSubHeader + " " + classes.headerPadding}>
      Targets
    </Typography>
    <Tabs value={0} textColor="primary" indicatorColor="primary" className={classes.tabs} centered>
      <Tab label="Monthly"/>
      <Tab label="Quarterly"/>
      <Tab label="Yearly"/>
    </Tabs>
    </Grid>
    <Grid item xs={12} md={6} sm={6}>
      <OrdWiseSaleTarget/>
    </Grid>
    <Grid item xs={12} md={6}  sm={6}>
      <MonthOnMonthTarget/>
    </Grid>
  </Grid>;
}

const dataBaseStyles = makeStyles((theme) => ({
  horizontalScrollable: {
    padding: 10,
    backgroundColor: '#fff',
    overflowX: 'scroll',
    '& > .container *': {
      display: 'inline-block',
    },
  }
}));

function DataCards(props) {
  const classes = dataBaseStyles();
  const data = [{
    'title': 'Highest earning from',
    'value': '₹ 202K',
    'type': 'Cipla Optimus'
  }, {
    'title': 'Top selling product',
    'value': 'Wysolone',
    'type': '₹ 20K'
  }, {
    'title': 'Total earning from Pfizer products',
    'value': '₹ 20K',
    'type': ''
  }, {
    'title': 'Highest earning from',
    'value': '2,02,100',
    'type': 'Cipla Optimus'
  }];
  return <div className={classes.horizontalScrollable}>
    <div className='container' style={{width: data.length * 150}}>
      {
        data.map(d => {
          return <DataCard title={d.title} value={d.value} type={d.type}/>
        })
      }
    </div>
  </div>;
}

const organizationSelectStyles = makeStyles((theme) => ({
  root: {
    padding: 10
  },
  formControl: {
    color: '#fff',
    '& > select': {
      padding: 5
    }
  }
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 26px 5px 12px',
  },
}))(InputBase);

function OrganizationSelect() {
  const classes = organizationSelectStyles();
  return <div className={classes.root}><FormControl variant="outlined" className={classes.formControl} fullWidth>
    <Select native value={10} input={<BootstrapInput/>}>
      <option value={10}>ALL</option>
      <option value={20}>Cipla Optimus</option>
      <option value={30}>Zydus Geo</option>
      <option value={40}>Pfizer Icon</option>
      <option value={50}>Pfizer Aspira</option>
    </Select>
  </FormControl>
  </div>
};

const monthOnMonthStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    backgroundColor: '#fff',
  }
}));

function MonthOnMonthTarget(props) {
  const classes = monthOnMonthStyles();
  const data = {
    organization: 'Cipla Optimus',
    value: '₹ 223K',
    target: '₹ 303K',
  };
  return <div className={classes.root}>
    <ProductWiseTargetCard {...data} />
  </div>
}

const orgWiseSaleTargetStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    backgroundColor: '#fff'
  },
  card: {
    backgroundColor: '#fff',
  },
  title: {
    color: '#fb8c00'
  }
}));

function OrdWiseSaleTarget(props) {
  const classes = orgWiseSaleTargetStyles();
  return <div className={classes.root}>
    <OrganizationSaleTargetChart/>
  </div>
}
