import BadRequestError from "./exceptions/BadRequestError";
import NetworkError from "./exceptions/NetworkError";
import ServerError from "./exceptions/ServerError";

/**
 * Response statuses
 */
export const RESPONSE_STATUSES = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
};

/**
 * Header constants
 *
 * @type {{}}
 */
export const HEADER_CONSTANTS = {
  CONTENT_TYPE: 'Content-Type',
  ACCEPT: 'Accept',
  APPLICATION_JSON: 'application/json'
};

/**
 * Handle rest promise
 *
 * @param url
 * @param options
 * @return {Promise<Response | {data: Response, status: *}>}
 */
export const jsonCall = (url, options = {}) => {
  options.headers = addJsonHeaders(options.headers);
  return fetch(url, options).then(handleResponse)
      .then(convertToJson)
      .then(handleSuccess)
      .catch(error => {
        console.log(error);
        throw(error)
      });
};

/**
 * Handle file download promise
 *
 * @param url
 * @param options
 * @return {Promise<Response | {data: Response, status: *}>}
 */
export const fileCall = (url, options = {}) => {
  options.headers = addFileDownloadHeaders(options.headers);
  return fetch(url, options)
      .then(response => {
        for (let entry of response.headers.entries()) {
          console.log(entry);
        }
        const filename = response.headers.get('Content-Disposition').split('filename=')[1];
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();
        });
      });
};

/**
 * Handle rest promise
 *
 * @param url
 * @param options
 * @return {Promise<Response | {data: Response, status: *}>}
 */
export const formCall = (url, options = {}) => {
  options.headers = addFormHeaders(options.headers);
  return fetch(url, options).then(handleResponse)
      .then(convertToJson)
      .then(handleSuccess)
      .catch(error => {
        console.log(error);
        throw(error)
      });
};

/**
 * Handle rest errors
 *
 * @param response
 */
const handleResponse = (response) => {
  if ((response.status === RESPONSE_STATUSES.SUCCESS) || (response.status === RESPONSE_STATUSES.BAD_REQUEST))
    return response;
  else if (response.status === RESPONSE_STATUSES.SERVER_ERROR)
    throw new ServerError('Server Not Responding.');
  else if (response.status === RESPONSE_STATUSES.NOT_FOUND)
    throw new ServerError("Route not found.");
  else
    throw new NetworkError('Please check your network connection');
};

/**
 * Format success response
 *
 * @param response
 * @return {*}
 */
const convertToJson = (response) => {
  return response.json();
};

/**
 * Handle Rest Response
 *
 * @param response
 * @return {*}
 */
const handleSuccess = (response) => {
  if (response.errors)
    throw new BadRequestError(response.errors);
  return response;
};

/**
 * Prepare request headers
 *
 * @return {Headers}
 */
const addJsonHeaders = (headers) => {
  headers.append(HEADER_CONSTANTS.CONTENT_TYPE, HEADER_CONSTANTS.APPLICATION_JSON);
  headers.append(HEADER_CONSTANTS.ACCEPT, HEADER_CONSTANTS.APPLICATION_JSON);
  return headers;
};

/**
 * Prepare request headers
 *
 * @return {Headers}
 */
const addFileDownloadHeaders = (headers) => {
  return headers;
};

/**
 * Prepare request headers
 *
 * @return {Headers}
 */
const addFormHeaders = (headers) => {
  headers.append(HEADER_CONSTANTS.ACCEPT, HEADER_CONSTANTS.APPLICATION_JSON);
  return headers;
};