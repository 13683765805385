import React from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import {useForm} from "react-hook-form";
import {db} from "../../firebase";
import Insurance from "../models/Insurance";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10
  },
}));
const InsuranceForm = React.memo((props) => {
  const {register, errors, handleSubmit} = useForm();
  const {insurance, onInsuranceUpdate, onUpdate} = props;
  const classes = useStyles();

  /**
   * Save insurance
   *
   * @param data
   */
  const onSubmit = data => {
    let promise;
    let formattedData = Insurance.fromJson({...insurance, ...data});
    formattedData.refreshTimeStamp(new Date().getTime());
    formattedData.status = "processing";
    if (insurance.id)
      promise = updateInsurance(insurance.id, formattedData.toJson());
    else
      promise = createInsurance(formattedData.toJson());
    handlePromise(promise);
  };

  /**
   * Handle promise
   *
   * @param promise
   */
  const handlePromise = (promise) => {
    promise.then(doc => {
      onInsuranceUpdate();
    }).catch(error => {
      console.log("Error updating insurance:", error);
    });
  };

  const createInsurance = (data) => {
    return db.collection("insurances").add(data);
  };

  const updateInsurance = (id, data) => {
    return db.collection("insurances").doc(id).set(data);
  };

  const handleMultiChange = event => {
    onUpdate("no_of_months", parseInt(event.target.value));
  }

  const dateValidation = value => {
    var years = moment().diff(value, 'years');
    return years >= 18 && years <= 60; 
  }

  return <form noValidate className={classes.root} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormControl fullWidth variant="filled" margin="none">
          <InputLabel>No. of months</InputLabel>
          <Select native defaultValue={insurance.no_of_months}
                  inputRef={register({required: true})}
                  onChange={handleMultiChange}
                  inputProps={{name: 'no_of_months'}} error={!!errors.gender}>
            <option aria-label="None" value=""/>
            <option value={3}>3 Months (Only 150 per month)</option>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" gutterBottom>
          Points <b>{insurance.totalAmount()}</b> will be deducted from your Wallet.
        </Typography>
      </Grid>
    </Grid>
    <TextField fullWidth={true} variant="filled" margin="normal" name="first_name"
               label="FirstName" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})} type="text"
               defaultValue={insurance.first_name} error={!!errors.first_name}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="last_name"
               label="LastName" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})} type="text"
               defaultValue={insurance.last_name} error={!!errors.last_name}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="mobile"
               label="Mobile" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})}
               defaultValue={insurance.mobile} error={!!errors.mobile}/>
    <FormControl fullWidth variant="filled" margin="normal">
      <InputLabel>Gender</InputLabel>
      <Select native defaultValue={insurance.gender}
              inputRef={register({required: true})}
              inputProps={{name: 'gender'}} error={!!errors.gender}>
        <option aria-label="None" value=""/>
        <option value={"male"}>Male</option>
        <option value={"female"}>Female</option>
      </Select>
    </FormControl>
    <TextField fullWidth={true} id="date" label="Birthday" type="date" variant="filled"
               margin="normal" InputLabelProps={{shrink: true}} 
               inputRef={register({required: true, validate: value => dateValidation(value) || 'Age should be between 18 to 60.'})}
               inputProps={{name: 'date_of_birth'}} format="dd/MM/yyyy"
               helperText={errors.date_of_birth?.message}
               defaultValue={insurance.date_of_birth} error={!!errors.date_of_birth}/>
    <FormControl fullWidth variant="filled" margin="normal">
      <InputLabel>Language</InputLabel>
      <Select native defaultValue={insurance.language}
              inputRef={register({required: true})}
              inputProps={{name: 'language'}} error={!!errors.language}>
        <option aria-label="None" value=""/>
        <option value="English">English</option>
        <option value="Hindi">Hindi</option>
        <option value="Kannada">Kannada</option>
        <option value="Tamil">Tamil</option>
        <option value="Malayalam">Malayalam</option>
        <option value="Telugu">Telugu</option>
        <option value="Bengali">Bengali</option>
        <option value="Konkani">Konkani</option>
      </Select>
    </FormControl>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" size="medium"
                startIcon={<SaveIcon/>} fullWidth type="submit">
          Submit
        </Button>
      </Grid>
    </Grid>
  </form>;
});

export default InsuranceForm;
