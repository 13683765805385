export default class User {
  id;
  firm_name = "";
  mobile = "";
  pan = "";
  status = "";

  static fromJson(info) {
    let user = new User()
    user.id = info['id'];
    user.firm_name = info['firm_name'];
    user.mobile = info['mobile'];
    user.pan = info['pan'];
    user.status = info['status'];
    return user;
  }

  static fromSnapshot(data) {
    let user = new User();
    user.id = data.id;
    user.firm_name = data.data()['firm_name'];
    user.mobile = data.data()['mobile'];
    user.pan = data.data()['pan'];
    user.status = data.data()['status'] ?? 'pending';
    return user;
  }

  refreshTimeStamp(timestamp) {
    if (!this.created_at)
      this.created_at = timestamp;
    this.updated_at = timestamp;
  }

  toJson() {
    return {
      "firm_name": this.firm_name,
      "mobile": this.mobile,
      "created_at": this.created_at,
      "updated_at": this.updated_at,
      "status": this.status
    }
  }
}
