import React from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import {useForm} from "react-hook-form";
import Address from "../models/address";
import {db} from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10
  },
}));

const AddressForm = (props) => {
  const {register, errors, handleSubmit} = useForm();
  const classes = useStyles();

  /**
   * Save address
   *
   * @param data
   */
  const onSubmit = data => {
    let promise;
    const address = new Address({...props.address, ...data});
    address.refreshTimeStamp(new Date().getTime());
    if (props.address.id)
      promise = updateAddress(props.address.id, data);
    else
      promise = createAddress(data);
    handlePromise(promise);
  };

  const handlePromise = (promise) => {
    promise.then(doc => {
      props.onAddressUpdate();
    }).catch(error => {
      console.log("Error updating address:", error);
    });
  };

  const createAddress = (data) => {
    return db.collection("delivery_addresses").add(data);
  };

  const updateAddress = (id, data) => {
    return db.collection("delivery_addresses").doc(id).set(data);
  };

  return <form noValidate className={classes.root} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
    <TextField fullWidth={true} variant="filled" margin="normal" name="line"
               label="Shop no./Building/Street" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})}
               defaultValue={props.address.line} error={!!errors.line}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="landmark"
               label="Landmark" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})}
               defaultValue={props.address.landmark} error={!!errors.landmark}/>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField fullWidth={true} variant="filled" margin="normal" name="pincode"
                   label="Zip Code" InputLabelProps={{shrink: true}}
                   inputRef={register({required: true, minLength: 6, maxLength: 6})}
                   defaultValue={props.address.pincode} error={!!errors.pincode}/>
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth={true} variant="filled" margin="normal" name="area"
                   label="Area" InputLabelProps={{shrink: true}}
                   inputRef={register()}
                   defaultValue={props.address.area} error={!!errors.area}/>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField fullWidth={true} variant="filled" margin="normal" name="taluka"
                   label="Taluka" InputLabelProps={{shrink: true}}
                   inputRef={register({required: true})}
                   defaultValue={props.address.taluka} error={!!errors.taluka}/>
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth={true} variant="filled" margin="normal" name="district"
                   label="District" InputLabelProps={{shrink: true}}
                   inputRef={register({required: true})}
                   defaultValue={props.address.district} error={!!errors.district}/>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField fullWidth={true} variant="filled" margin="normal" name="state"
                   label="State" InputLabelProps={{shrink: true}}
                   inputRef={register({required: true})}
                   defaultValue={props.address.state} error={!!errors.state}/>
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth={true} variant="filled" margin="normal" name="country"
                   label="Country" InputLabelProps={{shrink: true}}
                   inputRef={register({required: true})}
                   defaultValue={props.address.country} error={!!errors.country}/>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" size="medium" startIcon={<SaveIcon/>} fullWidth type="submit">
          Save
        </Button>
      </Grid>
    </Grid>
  </form>;
};

export default AddressForm;
