import React, {useEffect, useState} from "react";
import {getTotals} from "../../../../../domain/services/DashboardService";
import Constants from "../../../../../../Constants";
import {MonthlyConversionGraph} from "./chart";
import {fetchBalances} from "../../../../../domain/services/BalanceService";
import Balance from "../../../../../domain/models/Balance";
import Amount from "../../../independent/amount";
import TableLoading from "../../../independent/table_loading";

function useBalances() {
  const [isLoading, setIsLoading] = useState(false);
  const [balances, setBalances] = useState([]);
  /**
   * Get totals
   */
  const requestTotals = () => {
    setIsLoading(true);
    fetchBalances().then(json => {
      setBalances(json['balances'].map(b => new Balance(b)));
      setIsLoading(false);
    })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      });
  }
  useEffect(requestTotals, []);

  return [isLoading, balances];
}

export default function BalanceList() {
  let [isLoading, balances] = useBalances();

  return <div className="">
    <div className="card mb-0">
      <div className="card-body pb-0">
        <h5 className="card-title">Organizations' passbook</h5>
        <hr/>
        <div className="card-table-responsive">
          <table className="table table-striped">
          <thead>
          <tr>
            <th>ID</th>
            <th>Organization</th>
            <th>Available Points</th>
            <th>Redeemable Points</th>
            <th>Pending Reward Points</th>
            <th>Converted Points</th>
            <th>Pending requests from</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            isLoading && <TableLoading colspan="7"/>
          }
          {
            balances.map(b => {
              return <tr key={b.id}>
                <td>{b.id}</td>
                <td>{b.name}</td>
                <td>{b.current_balance}</td>
                <td>
                  <span className="pr-2">{Constants.numberWithCommas(b.redeemable_points)}</span>
                  (<Amount balance={b.redeemableRecharge()}/>)
                </td>
                <td>
                  <span className="pr-2">{Constants.numberWithCommas(b.total_pending_amount)}</span>
                  (<Amount balance={b.pendingRecharge()}/>)
                </td>
                <td>
                  {Constants.numberWithCommas(b.total_processed_amount)}
                </td>
                <td>{Constants.standardDateFormat(b.min_pending_created_at)}</td>
                <td></td>
              </tr>
            })
          }
          </tbody>
        </table>
        </div> 
      </div>
    </div>
  </div>;
}