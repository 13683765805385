import React from 'react';
import './App.css';
import {Route, Switch} from "react-router-dom";
import indexRoutes from "./routes/index.js";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import orange from '@material-ui/core/colors/orange';
import blueGrey from '@material-ui/core/colors/blueGrey';
import {UserProvider} from "./wallet/domain/controllers/userController";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 0
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: blueGrey,
  },
  status: {
    danger: 'orange',
  },
});

function App() {
  const classes = useStyles();
  return (<ThemeProvider theme={theme}>
    <UserProvider>
      <Container className={classes.root} maxWidth="xl">
        <Switch>
          {
            indexRoutes.map((prop, key) => {
              return <Route path={prop.path} component={prop.component} exact={prop.exact} key={key}/>;
            })
          }
        </Switch>
      </Container>
    </UserProvider>
  </ThemeProvider>);
}

export default App;
