import {fileCall, formCall, jsonCall} from "./Rest";
import Utils from "../../../Utils";

/**
 * Get request
 */
export const get = (url, params = {}) => {
  let rest_url = new URL(url);
  rest_url = addGETParams(rest_url, params);

  return jsonCall(rest_url, {
    headers: addAuthTokens(new Headers()),
  });
};

/**
 * Get request
 */
export const download = (url, params = {}) => {
  let rest_url = new URL(url);
  rest_url = addGETParams(rest_url, params);

  return fileCall(rest_url, {
    headers: addAuthTokens(new Headers()),
    method: 'POST',
  });
};

/**
 * Post request
 *
 * @param url
 * @param data
 * @return {Promise<{data: Response, status: *}>}
 */
export const post = (url, data) => {
  return jsonCall(url, {
    headers: addAuthTokens(new Headers()),
    method: 'POST',
    body: JSON.stringify(data)
  });
};

/**
 * Put request
 *
 * @param url
 * @param data
 * @return {Promise<{data: Response, status: *}>}
 */
export const put = (url, data) => {
  return jsonCall(url, {
    headers: addAuthTokens(new Headers()),
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

/**
 * Post form for upload
 *
 * @param url
 * @param data
 * @return {Promise<{data: Response, status: *}>}
 */
export const multipartForm = (url, data) => {
  const headers = addAuthTokens(new Headers());
  return formCall(url, {
    headers: headers,
    method: 'POST',
    body: data
  });
};

/**
 * Delete request
 *
 * @param url
 * @return {Promise<{data: Response, status: *}>}
 */
export const remove = (url) => {
  return jsonCall(url, {
    headers: addAuthTokens(new Headers()),
    method: 'DELETE'
  });
};

/**
 * Add auth token to header
 *
 * @param headers
 */
const addAuthTokens = (headers) => {
  let token = Utils.getAuthToken();
  if (token.length > 0) {
    headers.append(process.env.REACT_APP_AUTH_TOKEN_HEADER, token);
    headers.append(process.env.REACT_APP_AUTH_TOKEN_KEY, token);
  }
  return headers;
};

/**
 * Add get params to url
 * @param url
 * @param params
 */
const addGETParams = (url, params) => {
  url.search = new URLSearchParams(params);
  return url;
};
