import React from "react";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SaveIcon from '@material-ui/icons/Save';
import {useForm} from "react-hook-form";
import {db} from "../../firebase";
import User from "../models/User";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10
  },
}));
const RegistrationForm = React.memo((props) => {
  const {register, errors, handleSubmit} = useForm();
  const {user, onUserUpdate, onUpdate} = props;
  const classes = useStyles();

  /**
   * Save User
   *
   * @param data
   */
  const onSubmit = data => {
    let promise;
    let formattedData = User.fromJson({...user, ...data});
    formattedData.refreshTimeStamp(new Date().getTime());
    formattedData.status = "pending";
    if (user.id)
      promise = update(user.id, formattedData.toJson());
    else
      promise = create(formattedData.toJson());
    handlePromise(promise);
  };

  /**
   * Handle promise
   *
   * @param promise
   */
  const handlePromise = (promise) => {
    promise.then(doc => {
      onUserUpdate();
    }).catch(error => {
      console.log("Error updating user:", error);
    });
  };

  const create = (data) => {
    return db.collection("cpay_users").add(data);
  };

  const update = (id, data) => {
    return db.collection("cpay_users").doc(id).set(data);
  };

  return <form noValidate className={classes.root} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
    <TextField fullWidth={true} variant="filled" margin="normal" name="firm_name"
               label="FirmName" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})} type="text"
               defaultValue={user.firm_name} error={!!errors.firm_name}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="mobile"
               label="Mobile" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})} type="text"
               defaultValue={user.mobile} error={!!errors.mobile}/>
    <TextField fullWidth={true} variant="filled" margin="normal" name="pan"
               label="Pan" InputLabelProps={{shrink: true}}
               inputRef={register({required: true})}
               defaultValue={user.pan} error={!!errors.pan}/>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" size="medium"
                startIcon={<SaveIcon/>} fullWidth type="submit">
          Submit
        </Button>
      </Grid>
    </Grid>
  </form>;
});

export default RegistrationForm;
