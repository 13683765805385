import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import AddressForm from "../components/AddressForm";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Address from "../models/address";
import {makeStyles} from '@material-ui/core/styles';
import GiftInfoCard from "../components/GiftInfoCard";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {
  getDeliveryAddressById,
  getGiftStatusForChannelPartner,
  markAddressVerifiedForGift
} from "../services/FirebaseService";
import DocketTrackingCard from "../components/DockerTrackingCard";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
export default function GoApptivGiftStatus(props) {
  const classes = useStyles();
  const [giftStatus, fetchGiftStatus] = useGiftStatus(parseInt(props.match.params.id));
  const address = useDeliveryAddress(giftStatus);
  const [updatingAddress, setUpdatingAddress] = useState(false);

  /**
   * On address updated successfully
   */
  const onAddressUpdatedSuccessfully = () => {
    Swal.fire({
      title: 'Delivery address updated!',
      text: 'Your address is updated successfully.',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'OK!'
    }).then((result) => {
      if (result.value)
        markAddressVerified();
    });
  };

  const markAddressVerified = () => {
    markAddressVerifiedForGift(giftStatus.id).then(() => {
      Swal.fire(
        'Approved!',
        'Your order is placed. Keep yourself updated with the docket status here.',
        'success'
      );
      setUpdatingAddress(false);
      fetchGiftStatus();
    })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const goBack = () => {
    let BackButton = window['BackButton'];
    if (BackButton)
      BackButton.goBack();
  };

  return <Grid container>
    <Grid item xs={12}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={goBack}>
            <ArrowBack/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            GoApptiv safety kit
          </Typography>
        </Toolbar>
      </AppBar>
      {
        giftStatus.status === 'pending' && !updatingAddress &&
        <StageOne data={giftStatus} showActions={true} goToNextStep={() => setUpdatingAddress(true)}/>
      }
      {
        updatingAddress && address.id &&
        <StageTwo address={address} onAddressUpdate={onAddressUpdatedSuccessfully}/>
      }
      {
        giftStatus.status === 'address_verified' &&
        <StageThree data={giftStatus}/>
      }
    </Grid>
  </Grid>;
}

function useGiftStatus(channelPartnerId) {
  const [freeGifts, setFreeGifts] = useState({});

  const fetchGiftStatus = () => {
    console.log("fetching gift status...");
    getGiftStatusForChannelPartner(channelPartnerId).then(querySnapshot => {
      setFreeGifts({id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data()});
    }).catch(error => {
      console.log("Error getting documents: ", error);
    });
  };

  useEffect(() => {
    fetchGiftStatus();
  }, [channelPartnerId]);

  return [freeGifts, fetchGiftStatus];
}

function useDeliveryAddress(giftStatus) {
  const [address, setAddress] = useState({});

  useEffect(() => {
    if (giftStatus.id) {
      console.log("fetching delivery address...");
      getDeliveryAddressById(giftStatus.delivery_address.id).then(querySnapshot => {
        setAddress(Address.fromSnapshot(querySnapshot));
      }).catch(error => {
        console.log("Error getting address: ", error);
      });
    }
  }, [giftStatus.id]);

  return address;
}

const StageOne = (props) => {
  return <div>
    <div style={{margin: 10, marginBottom: 40}}>
      <GiftInfoCard data={props.data}/>
    </div>
    {
      props.showActions &&
      <Button variant="contained" color="primary" className="bottom-button"
              size="large" endIcon={<Icon>send</Icon>} fullWidth onClick={props.goToNextStep}>
        Click here to verify your address
      </Button>
    }
  </div>
};

const StageTwo = (props) => {
  return <div>
    <div style={{margin: 10}}>
      <Typography gutterBottom variant="h5" component="h2">
        Verify your address
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Please verify and correct your address as we are going to send the free kit your this address
      </Typography>
    </div>
    <AddressForm address={props.address} onAddressUpdate={props.onAddressUpdate}/>
  </div>;
};

const StageThree = (props) => {
  return <DocketTrackingCard data={props.data}/>;
};
